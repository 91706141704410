import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
// import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import Appointments from './pages/Appointments';
import NotFound from './pages/Page404';
import UnderConstruction from './pages/UnderConstruction';
import {getIsLogged} from './Helpers/Util';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    !getIsLogged() ? {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { element: <Navigate to="/login" replace /> },
        { path: 'login', element: <Login /> },
        // { path: 'register', element: <Register /> },
      ]
    } : {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/home" replace /> },
        { path: 'home', element: <DashboardApp /> },
        { path: 'appointments', element: <Appointments /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'blog', element: <Blog /> },
      ]
    },
    // getIsLogged() && {
    //   path: '/dashboard',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/dashboard/home" replace /> },
    //     { path: 'home', element: <DashboardApp /> },
    //     { path: 'appointments', element: <Appointments /> },
    //     { path: 'products', element: <Products /> },
    //     { path: 'blog', element: <Blog /> },
    //     { path: 'blog', element: <Blog /> },
    //   ]
    // },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: 'under-construction', element: <UnderConstruction /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
