// material
import { Box, Grid, Container, Typography, Card } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
import { fShortenNumber } from '../utils/formatNumber';
import { alpha, styled } from '@mui/material/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import firebase from 'firebase/firebase';
import { getUser } from '../Helpers/Util';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: '0px 0px 15px -10px #000000',
    textAlign: 'left',
    padding: theme.spacing(2, 0),
    color: theme.palette.common.darker,
    backgroundColor: theme.palette.background.default,
    webkitBoxShadow: '0px 0px 15px -2px #000000',
  }));

  const [allAppoints, setAllAppoints] = useState('Loading...');
  const [pendingAppoints, setPendingAppoints] = useState('Loading...');
  const [completedAppoints, setCompletedAppoints] = useState('Loading...');

  const [todayAppoints, setTodayAppoints] = useState('Loading...');

  const [onlineAppoints, setOnlineAppoints] = useState('Loading...');
  const [appAppoints, setAppAppoints] = useState('Loading...');

  const [totalRevenue, setTotalRevenue] = useState('Loading...');
  const [manualRevenue, setManualRevenue] = useState('Loading...');
  const [appRevenue, setAppRevenue] = useState('Loading...');

  const database = firebase.firestore();

  const uid = getUser().uid;

  const numberCSS = {
    color: '#1AB084'
  }

  function loadData() {
    let p = 0;
    let c = 0;

    database.collection('appointment')
      .where('merchantID', '==', uid)
      .where('status', '!=', 'finished')
      .get().then(data => {
        setPendingAppoints(data.docs.length);
        p = data.docs.length;
      }).catch(e => {
      setPendingAppoints(0);
      p = 0;
      setAllAppoints(p + c);
    });

    database.collection('appointment')
      .where('merchantID', '==', uid)
      .where('status', '==', 'finished')
      .get().then(data => {
        setCompletedAppoints(data.docs.length);
        c = data.docs.length;

        //set all appointments
        setAllAppoints(p + c);
      }).catch(e => {
      setCompletedAppoints(0);
      c = 0;
      setAllAppoints(p + c);
    });

    const start_date = firebase.firestore.Timestamp.fromDate(new Date( moment().format('YYYY-MM-DD') + " 00:00:00"));
    const end_date = firebase.firestore.Timestamp.fromDate(new Date(moment().format('YYYY-MM-DD') + " 23:59:59"));

    database.collection('appointment')
      .where('merchantID', '==', uid)
      .where('timeSlot', '>', start_date)
      .where('timeSlot', '<', end_date)
      .get().then(data => {
        setTodayAppoints(data.docs.length);
      }).catch(e => {
      setTodayAppoints('00');
    });

    database.collection('appointment')
      .where('merchantID', '==', uid)
      .where('type', '==', 'PERKS')
      .get().then(data => {
        setOnlineAppoints(data.docs.length);
    }).catch(e => {
      setOnlineAppoints('00');
    });

    database.collection('appointment')
      .where('merchantID', '==', uid)
      .where('type', '==', 'MANUAL')
      .get().then(data => {
      setAppAppoints(data.docs.length);
    }).catch(e => {
      setAppAppoints('00');
    });

    database.collection('appointment')
      .where('merchantID', '==', uid)
      .where('paymentStatus', '==', 'success')
      .get().then(data => {
        let tot = 0;
        data.docs.map(value => {
          tot = tot + value.data().total_price;
        });

      setTotalRevenue(tot);
    }).catch(e => {
      setTotalRevenue('00.00');
    });

    database.collection('appointment')
      .where('merchantID', '==', uid)
      .where('paymentStatus', '==', 'success')
      .where('type', '==', 'MANUAL')
      .get().then(data => {
      let tot = 0;
      data.docs.map(value => {
        tot = tot + value.data().total_price;
      });

      setManualRevenue(tot);
    }).catch(e => {
      setManualRevenue('00.00');
    });


    database.collection('appointment')
      .where('merchantID', '==', uid)
      .where('paymentStatus', '==', 'success')
      .where('type', '==', 'PERKS')
      .get().then(data => {
      let tot = 0;
      data.docs.map(value => {
        tot = tot + value.data().total_price;
      });

      setAppRevenue(tot);
    }).catch(e => {
      setAppRevenue('00.00');
    });

  }

  useEffect(() => {
    loadData();
  },[])

  return (
    <Page title="Dashboard | Perks">
      <Container maxWidth="xl" style={{maxWidth: '100%'}}>
        <Box>
          <Typography variant="h4">Appointments</Typography>
          <hr/>
        </Box>

        <Grid container xs={12} sm={12} md={12}>
          <Grid container xs={12} sm={12} md={12}>

            <Grid item xs={12} sm={4} md={4} pt={2} pl={1} pr={1}>
              <RootStyle>
                <Typography variant="subtitle1" ml={2}>
                  All Appointments
                </Typography>
                <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>
                  Total number of appointments
                </Typography>
                <Typography variant="h3" ml={2} style={numberCSS}>{allAppoints}</Typography>
              </RootStyle>
            </Grid>

            <Grid item xs={12} sm={4} md={4} pt={2} pl={1} pr={1}>
              <RootStyle>
                <Typography variant="subtitle1" ml={2}>
                  Pending Appointments
                </Typography>
                <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>
                  Appointments on pending
                </Typography>
                <Typography variant="h3" ml={2} style={numberCSS}>{pendingAppoints}</Typography>
              </RootStyle>
            </Grid>

            <Grid item xs={12} sm={4} md={4} pt={2} pl={1} pr={1}>
              <RootStyle>
                <Typography variant="subtitle1" ml={2}>
                  Completed Appointments
                </Typography>
                <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>
                  Completed appointments
                </Typography>
                <Typography variant="h3" ml={2} style={numberCSS}>
                  {completedAppoints}
                </Typography>
              </RootStyle>
            </Grid>

            <Grid item xs={12} sm={6} md={6} pt={2} pl={1} pr={1}>
              <RootStyle>
                <Typography variant="subtitle1" ml={2}>
                  Today's appointments
                </Typography>
                <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>
                  Total number of today's appointments
                  {/*{moment().format('YYYY-MM-DD')}*/}
                </Typography>
                <Typography variant="h3" ml={2} style={numberCSS}>
                  {todayAppoints}
                </Typography>
              </RootStyle>
            </Grid>

            <Grid item xs={12} sm={3} md={3} pt={2} pl={1} pr={1}>
              <RootStyle>
                <Typography variant="subtitle1" ml={2}>
                  Online Appointments
                </Typography>
                <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>
                  Appointments via perks
                </Typography>
                <Typography variant="h3" ml={2} style={numberCSS}>
                  {onlineAppoints}
                </Typography>
              </RootStyle>
            </Grid>

            <Grid item xs={12} sm={3} md={3} pt={2} pl={1} pr={1}>
              <RootStyle>
                <Typography variant="subtitle1" ml={2}>
                  Manual Appointments
                </Typography>
                <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>
                  Appointments added manually
                </Typography>
                <Typography variant="h3" ml={2} style={numberCSS}>
                  {appAppoints}
                </Typography>
              </RootStyle>
            </Grid>

          </Grid>
        </Grid>

        <div className="mt-lg-5">
        </div>

        <Box className="mt-3">
          <Typography variant="h4">Revenue</Typography>
          <hr/>
        </Box>

        <Grid container xs={12} sm={12} md={12}>
          <Grid container xs={12} sm={12} md={12}>

            <Grid item xs={12} sm={4} md={4} pt={2} pl={1} pr={1}>
              <RootStyle>
                <Typography variant="subtitle1" ml={2}>
                  Total Revenue
                </Typography>
                <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>
                  Total revenue generated
                </Typography>
                <Typography variant="h3" ml={2} style={numberCSS}>
                  {
                    totalRevenue.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'LKR'
                    })
                  }
                </Typography>
              </RootStyle>
            </Grid>

            <Grid item xs={12} sm={4} md={4} pt={2} pl={1} pr={1}>
              <RootStyle>
                <Typography variant="subtitle1" ml={2}>
                  Manual Revenue
                </Typography>
                <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>
                  Revenue generated from manual appointments
                </Typography>
                <Typography variant="h3" ml={2} style={numberCSS}>
                  {
                    manualRevenue.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'LKR'
                    })
                  }
                </Typography>
              </RootStyle>
            </Grid>

            <Grid item xs={12} sm={4} md={4} pt={2} pl={1} pr={1}>
              <RootStyle>
                <Typography variant="subtitle1" ml={2}>
                  Onile Revenue
                </Typography>
                <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>
                  Revenue generated from online appointments
                </Typography>
                <Typography variant="h3" ml={2} style={numberCSS}>
                  {
                    appRevenue.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'LKR'
                    })
                  }
                </Typography>
              </RootStyle>
            </Grid>

          </Grid>

          {/*<Grid container xs={12} sm={12} md={6} pl={1}>*/}

          {/*  <Grid item xs={12} sm={12} md={12}>*/}
          {/*    <RootStyle>*/}
          {/*      <Typography variant="subtitle1" ml={2}>*/}
          {/*        Revenue*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="subtitle2" style={{fontSize:'8pt', lineHeight:'21px'}} sx={{ opacity: 0.72 }} ml={2}>*/}
          {/*         Manual*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="subtitle2" style={{fontSize:'8pt', lineHeight:'21px'}} sx={{ opacity: 0.72 }} ml={2}>*/}
          {/*        Online*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="subtitle2" style={{fontSize:'8pt', lineHeight:'24px'}} sx={{ opacity: 0.72 }} ml={2}>*/}
          {/*        Total Revenue:*/}
          {/*      </Typography>*/}
          {/*    </RootStyle>*/}
          {/*  </Grid>*/}

          {/*  <Grid item xs={12} sm={12} md={6} pt={1} pr={1}>*/}
          {/*    <RootStyle>*/}
          {/*      <Typography variant="subtitle1" ml={2}>*/}
          {/*        Top Rated Service*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>*/}
          {/*        All time average merchant rating*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="h3" ml={2} style={numberCSS}>25</Typography>*/}
          {/*    </RootStyle>*/}
          {/*  </Grid>*/}

          {/*  <Grid item xs={12} sm={12} md={6} pt={1}>*/}
          {/*    <RootStyle>*/}
          {/*      <Typography variant="subtitle1" ml={2}>*/}
          {/*        Top Rated Employee*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="subtitle2" style={{fontSize:'8pt'}} sx={{ opacity: 0.72 }} ml={2}>*/}
          {/*        All time average merchant rating*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="h3" ml={2} style={numberCSS}>25</Typography>*/}
          {/*    </RootStyle>*/}
          {/*  </Grid>*/}

          {/*</Grid>*/}
        </Grid>


        {/*<Grid container spacing={3}>*/}
        {/*  <Grid item xs={12} sm={6} md={3}>*/}
        {/*    <AppNewUsers />*/}
        {/*  </Grid>*/}

        {/*  <Grid item xs={12} sm={6} md={3}>*/}
        {/*    <AppItemOrders />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={12} sm={6} md={3}>*/}
        {/*    <AppBugReports />*/}
        {/*  </Grid>*/}

        {/*  <Grid item xs={12} md={6} lg={8}>*/}
        {/*    <AppWebsiteVisits />*/}
        {/*  </Grid>*/}

        {/*  <Grid item xs={12} md={6} lg={4}>*/}
        {/*    <AppCurrentVisits />*/}
        {/*  </Grid>*/}

        {/*  <Grid item xs={12} md={6} lg={8}>*/}
        {/*    <AppConversionRates />*/}
        {/*  </Grid>*/}

        {/*  <Grid item xs={12} md={6} lg={4}>*/}
        {/*    <AppCurrentSubject />*/}
        {/*  </Grid>*/}

        {/*  <Grid item xs={12} md={6} lg={8}>*/}
        {/*    <AppNewsUpdate />*/}
        {/*  </Grid>*/}

        {/*  <Grid item xs={12} md={6} lg={4}>*/}
        {/*    <AppOrderTimeline />*/}
        {/*  </Grid>*/}

        {/*  <Grid item xs={12} md={6} lg={4}>*/}
        {/*    <AppTrafficBySite />*/}
        {/*  </Grid>*/}

        {/*  <Grid item xs={12} md={6} lg={8}>*/}
        {/*    <AppTasks />*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Container>
    </Page>
  );
}
