import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import './stepper.css';
// material
import {
  Box,
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import { UserListHead} from '../components/_dashboard/user';
//

import { Modal, Card, Form, FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from '../Helpers/Api';
import {
  getUser,
  getDays,
  getStylists,
  getAppointmentTimeSlots,
  getServices,
  getTimeSlotGap, getServiceCategories
} from '../Helpers/Util';
import moment from 'moment';
import { Rings } from  'react-loader-spinner';

////////////firebase
import firebase from 'firebase/firebase';
import { styled } from '@mui/material/styles';
import Collapsible from './PageParts/Collapsible';

/////////stepper
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

firebase.initializeApp(api.firebaseConfig);
const database = firebase.firestore();
////////////firebase

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: '0px 0px 15px -10px #000000',
  borderRadius: '12px !important',
  border: 'none !important',
  textAlign: 'left',
  padding: theme.spacing(1, 0),
  color: theme.palette.common.darker,
  backgroundColor: theme.palette.background.default,
  webkitBoxShadow: '0px 0px 15px -2px #000000',
}));

export default function Appointments() {
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //custom data
  const uid = getUser().uid;
  const [today, setToday] = useState(moment().format("YYYY-MM-DD"));

  const [employees, setEmployees] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  let [sts, setSTS] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [clientModalShow, setClientModalShow] = useState(false);

  const services = getServices();
  const serviceCategories = getServiceCategories();

  const [slot1, setSlot1] = useState(true);
  const [slot2, setSlot2] = useState(false);
  const [slot3, setSlot3] = useState(false);
  const [slot4, setSlot4] = useState(false);

  const [lastTime, setLastTime] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);

  const [servicesErrors, setServicesErrors] = useState('');
  const [detailsErrors, setDetailsErrors] = useState('');

  const [stepOneTop, setStepOneTop] = useState(true);
  const [stepTwoTop, setStepTwoTop] = useState(false);
  const [stepThreeTop, setStepThreeTop] = useState(false);
  const [stepFourTop, setStepFourTop] = useState(false);
  const [completedSteps, setCompletedSteps] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const [emptyMsg, setEmptyMsg] = useState('');

  function changeStage(stage = 0) {
    if(stage == 1) {
      if(selectedServices.length > 0) {
        setSlot1(true);
        setSlot2(true);
        setSlot3(true);
        setSlot4(true);

        setStepOneTop(false);
        setStepTwoTop(false);
        setStepThreeTop(false);
        setStepFourTop(false);

        setSlot2(false);
        setStepOneTop(true);
        setStepTwoTop(true);
        setCompletedSteps(1);
        setCurrentStep(2);
      } else {
        setServicesErrors('Please select service(s)');
      }
    }

    if(stage == 2) {
      setSlot1(true);
      setSlot2(true);
      setSlot3(true);
      setSlot4(true);

      setStepOneTop(false);
      setStepTwoTop(false);
      setStepThreeTop(false);
      setStepFourTop(false);

      setSlot3(false);
      setStepOneTop(true);
      setStepTwoTop(true);
      setStepThreeTop(true);
      setCompletedSteps(2);
      setCurrentStep(3);
    }

    if(stage == 3) {
      if(customerName === "" || customerMobile === "") {
        setDetailsErrors('Required customer full name and mobile number');
      } else {
        setSlot1(true);
        setSlot2(true);
        setSlot3(true);
        setSlot4(true);

        setStepOneTop(false);
        setStepTwoTop(false);
        setStepThreeTop(false);
        setStepFourTop(false);

        setSlot4(false);
        setStepOneTop(true);
        setStepTwoTop(true);
        setStepThreeTop(true);
        setStepFourTop(true);
        setCompletedSteps(3);
        setCurrentStep(4);
      }
    }

    if(stage == 4) {
      setSlot1(true);
      setSlot2(true);
      setSlot3(true);
      setSlot4(true);

      setStepOneTop(false);
      setStepTwoTop(false);
      setStepThreeTop(false);
      setStepFourTop(false);

      setSlot1(false);
      setStepOneTop(true);
      setCompletedSteps(0);
      setCurrentStep(1);
    }
  }

  function goBack(step) {
    if(step < currentStep) {
      if(step == 1) {
        changeStage(4);
      } else {
        changeStage(step - 1);
      }
    }
  }

  let [process_arr_loaded, setProcess_arr_loaded] = useState(false);

  function handleSelectedDate(date) {
    // setProcess_arr_loaded(false);
    setIsLoading(true);
    setDisabled(true);

    if(!moment(date).isValid() === true || date === "") {
      date = moment().format('YYYY-MM-DD');
      setToday(date);
    } else {
      setToday(date);
    }

    let day = moment(date).format('dddd');

    appointments = [];
    appointments.splice(0, appointments.length);

    setIsLoaded(false);
    getAppointmentsFireBase(date)
      .then(data => {
        data.docs.map(value => {
          appointments.push(value.data());
          // setAppointments(appointments => [...appointments, value.data()]);
        })

        processEmpAppointments();
        setIsLoaded(true);
      })

    switch (day) {
      case 'Monday':
        setSTS(timeSlots[0]);
        break;
      case 'Tuesday':
        setSTS(timeSlots[1]);
        break;
      case 'Wednesday':
        setSTS(timeSlots[2]);
        break;
      case 'Thursday':
        setSTS(timeSlots[3]);
        break;
      case 'Friday':
        setSTS(timeSlots[4]);
        break;
      case 'Saturday':
        setSTS(timeSlots[5]);
        break;
      case 'Sunday':
        setSTS(timeSlots[6]);
        break;
    }
  }

  function loadStylists() {
    let data = getStylists();

    data.map(value => {
      employees.push({id:value.name, label:value.name, docID:value.docID, image:value.image, alignRight:false});
    });
  }

  //handle set selected stylist and timeslot for new appointment
  const [selectedStylist, setSelectedStylist] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');

  let selectedTime = '';
  const [timeDiff, setTimeDiff] = useState(0);

  //past date and time validation method
  function checkPastDate() {
    setTimeDiff(moment().diff(today+' '+selectedTime+':00'));
  }

  function setSelectedStylistAndTimeSlot(stylist, timeslot) {
    setSelectedServices([]);
    selectedServices.splice(0, selectedServices.length);

    setSelectedStylist(stylist);
    setSelectedTimeSlot(timeslot);
    selectedTime = timeslot;

    checkPastDate();
  }

  //get appointments from firebase
  function getAppointmentsFireBase(date) {
    const start_date = firebase.firestore.Timestamp.fromDate(new Date( date + " 00:00:00"));
    const end_date = firebase.firestore.Timestamp.fromDate(new Date(date + " 23:59:59"));

    let data = database.collection('appointment')
      .where('merchantID', '==', uid)
      .where('timeSlot', '>', start_date)
      .where('timeSlot', '<', end_date)
      .get();

    return data;
  }

  let appointments = [];
  let [process_arr, setProcess_arr] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  async function getCustomerNameByID(type, id) {
    let name = id;

    if (type === 'PERKS') {
      await database.collection('customer')
        .where('docID', '==', id)
        .get().then(data => {
          data.docs.map(async value => {
            name = await value.data().firstName;
          })
        })
    }

    return name;
  }

  function processEmpAppointments() {
    setProcess_arr([]);
    process_arr.splice(0, process_arr.length);

    employees.map((employee, key) => {
      let emp_appointments = [];

      //if appointments more than 0, loading is enable
      if(appointments.length > 0) {
        setProcess_arr_loaded(true);
        setIsLoading(true);
      }

      appointments.map(async (appointment, key2) => {
        let res = getCustomerNameByID(appointment.type, appointment.customerID);
        let name;

        if (employee.docID == appointment.stylistID) {
          // emp_appointments.push(appointment);
          await res.then(async data => {
            name = await data;

            appointment.customerID = await name;
          })

          emp_appointments.push(appointment);

          if (appointments.length === (Number(key2 + 1))) {
            setTimeout(function() {
              setProcess_arr_loaded(false);
              setIsLoading(false);
              setDisabled(false);
            }, 1000);
          }
          setProcess_arr_loaded(false);
        }
        });

      setProcess_arr(process_arr => [...process_arr, emp_appointments]);
    });

    setIsLoading(false);
    setDisabled(false);
  }

  //add script of sweetalert 2
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//cdn.jsdelivr.net/npm/sweetalert2@11";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  //sweet alert popup method
  function popUpAlert(title = '', text = '', icon = 'success') {
    window.Swal.fire({
      icon: icon,
      title: title,
      text: text,
      allowOutsideClick: false
    })
  }

  useEffect(() => {
    loadStylists();
    setWeekDays(getDays());
    setTimeSlots(getAppointmentTimeSlots());

    if(sts.length === 0 || employees.length === 0 || services.length === 0) {
      setEmptyMsg('Please add employees, time slots and service(s) to activate calendar view');
    }
  }, [])

  //set last time by day
  useEffect(() => {
    let day = moment().format('dddd');

    switch (day) {
      case 'Monday':
        setLastTime(getDays()[0].end_time)
        break;
      case 'Tuesday':
        setLastTime(getDays()[1].end_time)
        break;
      case 'Wednesday':
        setLastTime(getDays()[2].end_time)
        break;
      case 'Thursday':
        setLastTime(getDays()[3].end_time)
        break;
      case 'Friday':
        setLastTime(getDays()[4].end_time)
        break;
      case 'Saturday':
        setLastTime(getDays()[5].end_time)
        break;
      case 'Sunday':
        setLastTime(getDays()[6].end_time)
        break;
    }
  },[])

  useEffect(() => {
    getAppointmentsFireBase(today)
      .then(data => {
        data.docs.map(value => {
          appointments.push(value.data());
        })

        processEmpAppointments();
        setIsLoaded(true);
        setDisabled(false);
      }).catch(e => console.log(e))
  }, [])

  ///for load current time slot
  useEffect(() => {
    let day = moment().format('dddd');

    if(timeSlots.length) {
      switch (day) {
        case 'Monday':
          setSTS(timeSlots[0]);
          break;
        case 'Tuesday':
          setSTS(timeSlots[1]);
          break;
        case 'Wednesday':
          setSTS(timeSlots[2]);
          break;
        case 'Thursday':
          setSTS(timeSlots[3]);
          break;
        case 'Friday':
          setSTS(timeSlots[4]);
          break;
        case 'Saturday':
          setSTS(timeSlots[5]);
          break;
        case 'Sunday':
          setSTS(timeSlots[6]);
          break;
      }
    }

  },[timeSlots])

  ////////////load table every 10 seconds
  const [lastUpdate, setLastUpdate] = useState(moment().format().toString());

  useEffect(() => {
    const interval = setInterval(() => {
      document.getElementById("btn_refresh").click();
      setLastUpdate(moment().format().toString());
    }, 120000);

    return () => clearInterval(interval);
  }, [])

  function refresh() {
    setIsLoading(true);
    setDisabled(true);
    setProcess_arr_loaded(false);

    setLastUpdate(moment().format().toString());

    if(!moment(today).isValid()) {
      setToday(moment().format('YYYY-MM-DD'));
    }

    let day = moment(today).format('dddd');

    appointments = [];
    appointments.splice(0, appointments.length);

    setIsLoaded(false);
    getAppointmentsFireBase(today)
      .then(data => {
        data.docs.map(value => {
          appointments.push(value.data());
        })

        processEmpAppointments();
        setIsLoaded(true);
        setDisabled(false);
      })

    switch (day) {
      case 'Monday':
        setSTS(timeSlots[0]);
        break;
      case 'Tuesday':
        setSTS(timeSlots[1]);
        break;
      case 'Wednesday':
        setSTS(timeSlots[2]);
        break;
      case 'Thursday':
        setSTS(timeSlots[3]);
        break;
      case 'Friday':
        setSTS(timeSlots[4]);
        break;
      case 'Saturday':
        setSTS(timeSlots[5]);
        break;
      case 'Sunday':
        setSTS(timeSlots[6]);
        break;
    }
  }

  function checkHasAppointTime(row_time, raw_appointments) {
    let has_appointment = false;

    raw_appointments.map(appointment => {
      let time = moment.unix(appointment.timeSlot.seconds).format('HH:mm')

      if(time === row_time) {
        has_appointment = true;

        return has_appointment;
      }
    })

    return has_appointment;
  }

  //check employee available for timeslot
  function isEmpAvailable(employee, currentTimeSlot) {
    let is_available = true;

    let allEmployees = getStylists();
    let time = today + ' ' +currentTimeSlot;

    allEmployees.map(value => {
      if(employee.docID === value.docID) {
        value.notWorkingDays.map(result0 => {
          if(today == moment.unix(result0.seconds).format('YYYY-MM-DD')) {
            is_available = false;
          }
        });

        value.notWorkingSlots.map(result => {
          if(time == moment.unix(result.seconds).format('YYYY-MM-DD HH:mm')) {
            is_available = false;
          }
        });
      }
    })

    return is_available;
  }

  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedClientServices, setSelectedClientServices] = useState([]);

  const [selectedTotal, setSelectedTotal] = useState(0);
  const [appointmentBtnDisabled, setAppointmentBtnDisabled] = useState(false);
  const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);

  const [DetailsAreaHide, setDetailsAreaHide] = useState(false);
  const [addServiceArea, setAddServiceArea] = useState(true);

  const [addedService, setAddedService] = useState([]);
  const [addServiceError, setAddServiceError] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);

  function openClientModal(appointment) {
    setSelectedClient(appointment);
    setSelectedTotal(appointment.total_price);
    setSelectedClientServices(appointment.services);

    setClientModalShow(!clientModalShow);
    setSaveBtnEnabled(false);
  }

  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesData, setSelectedServicesData] = useState([]);

  const [customerName, setCustomerName] = useState('');
  const [customerMobile, setCustomerMobile] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');

  let [totalPrice, setTotalPrice] = useState(0);

  function createSelectedServices(value) {
    let value_arr = value.split('#');

    let index = selectedServices.indexOf(value_arr[0]);
    setTotalPrice(0);

    let obj = {
      serviceID: value_arr[0],
      serviceName: value_arr[1],
      servicePrice: parseFloat(value_arr[2]),
    }

    if(index > -1) {
      selectedServicesData.splice(index, 1);
      setSelectedServices(selectedServices.filter(item => item !== value_arr[0]));
      setSelectedServicesData(selectedServicesData.filter(item => item !== obj));
      setTotalPrice(totalPrice - parseFloat(obj.servicePrice));
    } else {
      setSelectedServices(selectedServices => [...selectedServices, value_arr[0]]);
      setSelectedServicesData(selectedServicesData => [...selectedServicesData, obj]);
      setTotalPrice(totalPrice + parseFloat(obj.servicePrice));
    }
  }

  function addNewAppointment(stylistID) {
    let timeslotUnix = moment(today + ' ' + selectedTimeSlot).format();

    let date = new Date(timeslotUnix);

    let timeslot = firebase.firestore.Timestamp.fromDate(date);

    let ref = database.collection('appointment').doc();
    let newDocID = ref.id;

    ref.set(
      {
        merchantID: uid,
        customerID: customerName,
        appointmentID: newDocID,
        stylistID: selectedStylist.docID,
        timeSlot: timeslot,
        timeSlotCount: 1,
        total_price: totalPrice,
        services: selectedServicesData,
        note: "Mobile Number: " + customerMobile + ' ' + "Email: " + ((customerEmail === "") ? "N/A" : customerEmail),
        paymentMethod: "cash",
        status: "created",
        paidCoin: 0,
        timeSlotGap: getTimeSlotGap(),
        cardID: "",
        reviewStatus: "merchant",
        type: "MANUAL",
        paymentStatus: "pending",
      });

    //update stylist unavailable slots
    let merchantRef = database.collection('merchant').doc(uid).collection('stylists').doc(stylistID);

    let unavailableSlots;

    merchantRef.get().then(async data => {
      unavailableSlots = data.data().unavailable;

      unavailableSlots.push(timeslot);

      await merchantRef.update({ unavailable: unavailableSlots })
        .then(function() {

        }).catch(function(error) {
          popUpAlert('Failed', 'Failed make appointment', 'error')
        });
    });

    document.getElementById("btn_refresh").click();
    setLastUpdate(moment().format().toString());
    popUpAlert('Success', 'Appointment Added Successfully');
  }

  async function updateAppointment(id, type) {
    setAppointmentBtnDisabled(true);
    let ref = database.collection('appointment').doc(id);

    if(type === 'completed') {
      await ref.update({ paymentStatus: 'success', status: 'finished' })
        .then(function() {
          popUpAlert('Success', 'Appointment Completed')
        }).catch(function(error) {
          popUpAlert('Failed', 'Update failed', 'error')
        });

      setAppointmentBtnDisabled(false);
    }

    if(type === 'noShow') {
      await ref.update({ status: 'noShow'})
        .then(function() {
          popUpAlert('Success', 'Appointment Status Updated Successfully')
        }).catch(function(error) {
          popUpAlert('Failed', 'Update failed', 'error')
        });

      setAppointmentBtnDisabled(false);
    }

    setClientModalShow(!clientModalShow);
    document.getElementById("btn_refresh").click();
  }

  function changePriceInServices(index, e) {
    setSaveBtnEnabled(true);
    let newArr = [...selectedClientServices];
    newArr[index].servicePrice = e.target.value;

    setSelectedClientServices(newArr);
  }

  function updateAssignedServices(appointmentID) {
    setSaveBtnEnabled(false);
    setEnableEdit(false);
    let merchantRef = database.collection('appointment').doc(appointmentID);

    merchantRef.get().then(async data => {
      await merchantRef.update({ total_price: selectedTotal, services: selectedClientServices })
        .then(function() {
          setClientModalShow(!clientModalShow);
          document.getElementById("btn_refresh").click();
          popUpAlert('Success', 'Save changes successfully', 'success');
        }).catch(function(error) {
          setSaveBtnEnabled(true);
          popUpAlert('Failed', 'Failed make appointment', 'error');
        });
    });
  }

  function pushAddedService() {
    if(addedService.serviceID === "" ||
      addedService.serviceID === undefined ||
      addedService.servicePrice === "" ||
      addedService.servicePrice === undefined) {

      setAddServiceError(true);

    } else {
      let newArr = [...selectedClientServices];
      newArr.push(addedService);

      setSelectedClientServices(newArr);

      setDetailsAreaHide(false);
      setAddServiceArea(true);
      setSaveBtnEnabled(true);
      setAddServiceError(false);
    }
  }

  function deleteAddedService(index) {
    let newArr = [...selectedClientServices];
    newArr.splice(index, 1);

    setSelectedClientServices(newArr);

    setSaveBtnEnabled(true);
  }

  useEffect(() => {
    let tot = 0;

    selectedClientServices.map(val => {
      tot = (tot + Number(val.servicePrice));
    });

    setSelectedTotal(tot)
  },[selectedClientServices])

  return (
    <Page title="Appointments">
      <Modal
        style={{zIndex: 99999}}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(!modalShow)}
      >
        {(timeDiff < 0) && <Modal.Header>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={completedSteps} alternativeLabel>
              <Step color="#0EA577" active={stepOneTop} onClick={() => goBack(1)}>
                <StepLabel>{'Select Service'}</StepLabel>
              </Step>

              <Step color="#0EA577" active={stepTwoTop} onClick={() => goBack(2)}>
                <StepLabel>{'Schedule'}</StepLabel>
              </Step>

              <Step color="#0EA577" active={stepThreeTop} onClick={() => goBack(3)}>
                <StepLabel>{'Details'}</StepLabel>
              </Step>

              <Step color="#0EA577" active={stepFourTop} onClick={() => goBack(4)}>
                <StepLabel>{'Done'}</StepLabel>
              </Step>
            </Stepper>
          </Box>

        </Modal.Header>}
        <Modal.Body>

          {/*slot 1*/}
          {(timeDiff < 0) ?
            <div className='row' hidden={slot1}>
            <h5><strong>Services</strong></h5>
            <div className='col-sm-12'>
              {servicesErrors && <div className='alert alert-danger' style={{ fontWeight: '600' }}>
                {servicesErrors}
              </div>}
              {
                serviceCategories.map(category => {
                  return (
                    <Collapsible title={category}>
                      {services.map(value => {
                        if (category === value.service_category) {
                          return (
                            <Card style={{border: 'none'}}>
                              <Card.Body>

                                <RootStyle>
                                <div className='row'>
                                  <div className='col-sm-3 text-center'>
                                    <img style={{ width: '100%', height: '60px', borderRadius: '20px', marginLeft: '12px' }} src={value.image} alt='service image' />
                                  </div>
                                  <div className='col-sm-6'>
                                    <Card.Title>{value.name}</Card.Title>
                                    <Card.Text>
                                      Price: {value.price} LKR
                                    </Card.Text>
                                  </div>

                                  <div className='col-sm-3 text-center'>
                                    <Form>
                                      <Form.Check
                                        type='switch'
                                        id='custom-switch'
                                        value={value.docID + '#' + value.name + '#' + value.price}
                                        onChange={e => createSelectedServices(e.target.value)}
                                      />
                                    </Form>
                                  </div>
                                </div>
                                </RootStyle>

                              </Card.Body>
                            </Card>
                          );
                        }
                      })}
                    </Collapsible>
                  )
                })
              }
            </div>

            <div className='col-sm-12 mt-3'>
              <Button
                onClick={() => changeStage(1)}
                style={{ backgroundColor: '#1AB084', color: 'white', width: '100%', height: '60px' }}>Next</Button>
            </div>
          </div>: <center><div style={{marginBottom: '0'}} className="alert alert-warning">Cannot make appointment for past date or timeslot.</div></center>}
          {/*slot 1*/}

          {/*slot 2*/}
          {<div className='row' hidden={slot2}>

            <div className='col-sm-12 mt-3'>
              <div className="row">
                <div className='col-sm-4 text-center'>

                </div>

                <div className='col-sm-4 text-center'>
                  <img src={selectedStylist.image} alt='stylist img' style={{width: '100%', height: '150px', borderRadius: '100px'}}/>
                  <p style={{fontWeight: '600'}}>{selectedStylist.label}</p>
                </div>

                <div className='col-sm-4 text-center'>

                </div>
              </div>
            </div>

            <div className='col-sm-12 mt-3 text-center'>
              <p style={{marginBottom: 0}}><span style={{fontWeight: '600'}}>Selected Date:</span> <span>{moment(today).format('dddd, MMMM Do YYYY')}</span></p>
            </div>

            <div className='col-sm-12 mt-3 text-center'>
              <p style={{marginBottom: 0}}><span style={{fontWeight: '600'}}>Selected Time Slot:</span> <span>{moment(selectedTimeSlot, ["HH:mm"]).format("hh:mm A")}</span></p>
            </div>

            <div className='col-sm-12 mt-3'>
              <Button
                onClick={() => changeStage(2)}
                style={{ backgroundColor: '#1AB084', color: 'white', width: '100%', height: '60px' }}>Next</Button>
            </div>
          </div>}
          {/*slot 2*/}

          {/*slot 3*/}
          {<div className='row' hidden={slot3}>
            <div className='col-sm-12 mt-3'>
              {detailsErrors && <div className='alert alert-danger' style={{ fontWeight: '600' }}>
                {detailsErrors}
              </div>}
              <Card style={{
                border: 'none',
                borderRadius: '15px',
                webkitBoxShadow: '0px 0px 15px -10px #000000',
                boxShadow: '0px 0px 15px -10px #000000'
              }}>
                <Card.Body>

                  <FloatingLabel
                    controlId='floatingInput'
                    label='Full Name *'
                    onChange={(e) => setCustomerName(e.target.value)}
                    className='mb-3'
                  >
                    <Form.Control type='text' placeholder='Full Name' />
                  </FloatingLabel>

                  <FloatingLabel
                    controlId='floatingInput'
                    label='Mobile Number *'
                    onChange={(e) => setCustomerMobile(e.target.value)}
                    className='mb-3'
                  >
                    <Form.Control type='text' placeholder='Mobile Number' />
                  </FloatingLabel>

                  <FloatingLabel
                    controlId='floatingInput'
                    label='Email Address'
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    className='mb-3'
                  >
                    <Form.Control type='email' placeholder='name@example.com' />
                  </FloatingLabel>

                </Card.Body>
              </Card>
            </div>

            <div className='col-sm-12 mt-5'>
              <Button
                onClick={() => changeStage(3)}
                style={{ backgroundColor: '#1AB084', color: 'white', width: '100%', height: '60px' }}>Next</Button>
            </div>
          </div>}
          {/*slot 3*/}

          {/*slot 4*/}
          {<div className='col-sm-12 mt-3' hidden={slot4}>
            <Card style={{
              border: 'none',
              borderRadius: '15px',
              webkitBoxShadow: '0px 0px 15px -10px #000000',
              boxShadow: '0px 0px 15px -10px #000000'
            }}>
              <Card.Body style={{lineHeight: '20px'}}>
                <center>
                  <h4 style={{fontWeight: 600}} className="mb-2">Review & Add Appointment</h4>
                  <p><span style={{fontWeight: 600}}>Stylist:</span> {selectedStylist.label}</p>
                  <p><span style={{fontWeight: 600}}>Customer Name:</span> {customerName}</p>
                  <p><span style={{fontWeight: 600}}>Mobile Number:</span> {customerMobile}</p>
                  <p><span style={{fontWeight: 600}}>Email Address:</span> {(customerEmail === "") ? "N/A" : customerEmail}</p>
                  <p><span style={{fontWeight: 600}}>Selected Date:</span> {moment(today).format('dddd, MMMM Do YYYY')}</p>
                  <p><span style={{fontWeight: 600}}>Selected Time Slot:</span> {moment(selectedTimeSlot, ["HH:mm"]).format("hh:mm A")}</p>
                  <p><span style={{fontSize: '24px', fontWeight: 600}}>Estimated Total:</span> <span style={{fontSize: '26px'}}>{totalPrice} LKR</span></p>
                </center>
              </Card.Body>
            </Card>

            <div className='col-sm-12 mt-5'>
              <Button
                onClick={() => {
                  changeStage(4);
                  addNewAppointment(selectedStylist.docID);
                  setModalShow(!modalShow);
                }}
                style={{ backgroundColor: '#1AB084', color: 'white', width: '100%', height: '60px' }}>
                Add Appointment
              </Button>
            </div>
          </div>}
          {/*slot 4*/}

        </Modal.Body>
      </Modal>

      <Modal
        style={{zIndex: 99999}}
        // dialogClassName="my-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={clientModalShow}
        onHide={() => setClientModalShow(!clientModalShow)}
      >
        <Modal.Body>

          <div hidden={DetailsAreaHide}>
            <center>
              <div className="row mb-4">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <span style={{ fontWeight: '600', fontSize: '18px' }}>Customer Name: {selectedClient.customerID}</span>
                </div>
              </div>

              <div className="row" style={{ marginRight: '15px', marginLeft: '15px'  }}>
                <RootStyle>
                  <div className="row">
                    <div className='col-sm-3 text-center'>
                      <img style={{ width: '100%', height: '80px', borderRadius: '100px', marginLeft: '5px' }} src={selectedStylist.image} alt='service image' />
                    </div>
                    <div className="col-sm-8">
                      <Typography variant="subtitle1">
                        {selectedStylist.id}
                      </Typography>

                      <Typography variant="subtitle1" mt={1} style={{fontWeight: '600', color: '#1AB084'}}>
                        {moment(selectedTimeSlot, ["HH:mm"]).format("hh:mm A")}
                      </Typography>

                      <span mt={1} style={{fontWeight: '500', color: '#BDC3C7'}}>
                      {moment(today).format('dddd, MMMM Do YYYY')}
                    </span>
                    </div>
                  </div>
                </RootStyle>
              </div>

              <div className="row" style={{ marginRight: '15px', marginLeft: '15px'  }}>
                <div className="col-sm-9">
                  <Typography mt={2} variant="subtitle1" style={{color: '#707e7e', fontWeight: '600', textAlign: 'left'}}>
                    Services
                  </Typography>
                </div>
                {
                  (selectedClient.status === 'noShow' || selectedClient.status === 'finished') ?
                    null
                    :
                    <div className="col-sm-3">
                      <Typography
                        mt={2}
                        variant="subtitle1"
                        style={{
                          color: 'white',
                          fontWeight: '600',
                          textAlign: 'center',
                          backgroundColor: '#1AB084',
                          padding: '5px',
                          borderRadius: '5px',
                          cursor: 'pointer'
                        }}
                        onClick={() => setEnableEdit(!enableEdit)}
                      >
                        Edit
                      </Typography>
                    </div>
                }
              </div>

              <div className="row mt-3" style={{ marginRight: '15px', marginLeft: '15px' }}>
                <RootStyle>
                  <div className="row mt-2" style={{paddingRight: '15px', paddingLeft: '15px'}}>
                    <div className="col-sm-6" style={{fontWeight: '600', color: '#BDC3C7'}}>
                      Payment Method
                    </div>
                    <div className="col-sm-6" style={{fontWeight: '600', textAlign: 'right'}}>
                      {selectedClient.paymentMethod}
                    </div>
                    <div className="mt-3">
                    </div>
                    <hr/>
                  </div>

                  {
                    selectedClientServices.map((value, key) => {
                      return(
                        <div className="row mt-1" style={{paddingRight: '15px', paddingLeft: '15px'}}>
                          <div className="col-sm-6" style={{fontWeight: '600'}}>
                            {value.serviceName}
                          </div>

                          {
                            !enableEdit ?
                              <>
                              <div className='col-sm-3'
                                   style={{ color: '#1AB084', fontWeight: '600', textAlign: 'right' }}>
                                LKR
                              </div>
                              <div className='col-sm-3'
                                   style={{ color: '#1AB084', fontWeight: '600', textAlign: 'right' }}>
                                {(Math.round(value.servicePrice * 100) / 100).toFixed(2)}
                              </div>
                              </>
                              :
                              <div
                                className={(selectedClient.status === 'created') ? 'col-sm-4' : 'col-sm-6'}
                                style={{ color: '#1AB084', fontWeight: '600', textAlign: 'right' }}
                              >
                                <div className='input-group mb-2'>
                                  <div className='input-group-prepend'>
                                    <div
                                      className='input-group-text'
                                      style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        fontWeight: 600,
                                        color: '#1AB084'
                                      }}
                                    >
                                      LKR
                                    </div>
                                  </div>
                                  {
                                    (selectedClient.status === 'noShow' || selectedClient.status === 'finished') ?
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='inlineFormInputGroup'
                                        placeholder='Price'
                                        defaultValue={value.servicePrice}
                                        disabled={true}
                                        style={{
                                          border: 'none',
                                          borderBottom: '1px solid #1AB084',
                                          borderRadius: 0,
                                          fontWeight: 600,
                                          color: '#1AB084'
                                        }}
                                      /> :
                                      <input
                                        type='text'
                                        className='form-control'
                                        id='inlineFormInputGroup'
                                        placeholder='Price'
                                        defaultValue={value.servicePrice}
                                        onChange={(e) => changePriceInServices(key, e)}
                                        style={{
                                          border: 'none',
                                          borderBottom: '1px solid #1AB084',
                                          borderRadius: 0,
                                          fontWeight: 600,
                                          color: '#1AB084'
                                        }}
                                      />
                                  }

                                </div>
                              </div>
                          }

                            {
                              (selectedClient.status === 'noShow' || selectedClient.status === 'finished') ?
                                null
                                :
                                enableEdit ?
                                <div className="col-sm-2">
                                  <Icon
                                    icon={trash2Fill}
                                    width={22}
                                    height={22}
                                    style={{ cursor: 'pointer', color: '#E74C3C' }}
                                    onClick={() => deleteAddedService(key)}
                                  />
                                </div> : null
                            }
                          <div className="mt-3">
                          </div>
                          <hr/>
                        </div>
                      )
                    })
                  }

                  <div className="row mt-1" style={{paddingRight: '15px', paddingLeft: '15px'}}>
                    <div className="col-sm-6" style={{fontWeight: '600'}}>
                      Estimated Total
                    </div>
                    <div className="col-sm-3" style={{color: '#1AB084', fontWeight: '600', textAlign: 'right'}}>
                      LKR
                    </div>
                    <div className="col-sm-3" style={{color: '#1AB084', fontWeight: '600', textAlign: 'right'}}>
                      {(Math.round(selectedTotal * 100) / 100).toFixed(2)}
                    </div>
                    <div className="mt-3">
                    </div>
                    <hr/>

                    {saveBtnEnabled && (selectedClient.status !== 'noShow' || selectedClient.status !== 'finished') && <Button
                      variant='contained'
                      color='success'
                      disabled={!saveBtnEnabled}
                      className='table-btn available-btn'
                      onClick={() => updateAssignedServices(selectedClient.appointmentID)}
                    >
                      Save Changes
                    </Button>}
                  </div>

                </RootStyle>
              </div>

              <div className="row" style={{ marginRight: '15px', marginLeft: '15px'  }}>
                <div className="col-sm-12">
                  <Typography mt={2} variant="subtitle1" style={{color: '#707e7e', fontWeight: '600', textAlign: 'left'}}>
                    Note
                  </Typography>
                </div>
              </div>

              <div className="row mt-3" style={{ marginRight: '15px', marginLeft: '15px' }}>
                <RootStyle>
                  <div className="row mt-2" style={{paddingRight: '15px', paddingLeft: '15px'}}>
                    <div className="col-sm-12" style={{fontWeight: '600'}}>
                      {selectedClient.note}
                    </div>
                  </div>

                </RootStyle>
              </div>

              <div className="row mt-3">
                <div className="col-sm-12 col-md-12 col-lg-12">
                <span style={{ fontWeight: '600', fontSize: '18px' }}>Appointment Status:&nbsp;
                  {
                    (selectedClient.status === 'created') ? <span style={{color: '#E67E22'}}>Pending</span> :
                      (selectedClient.status === 'finished') ? <span style={{color: '#1AB084'}}>Completed</span> :
                        (selectedClient.status === 'noShow') ? <span style={{color: '#FF8F6B'}}>No Show</span> :
                          'Pending'
                  }
                </span>
                </div>
              </div>
            </center>

            {
              (selectedClient.status === 'noShow' || selectedClient.status === 'finished') ?
                null :
                <hr/>
            }

            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6">
                {
                  (selectedClient.status === 'noShow' || selectedClient.status === 'finished') ?
                  null : <Button
                    variant='outlined'
                    className="table-btn"
                    onClick={() => {
                      setDetailsAreaHide(true);
                      setAddServiceArea(false);
                    }}
                  >
                    Add Service
                  </Button>
                }
              </div>

              {
                (moment().diff(today + ' ' + '23:59:59') > 0) ?
                  (selectedClient.status === 'noShow' || selectedClient.status === 'finished') ?
                    null
                    :
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <Button
                        variant='contained'
                        color='warning'
                        disabled={appointmentBtnDisabled}
                        className='table-btn noShow-btn'
                        onClick={() => updateAppointment(selectedClient.appointmentID, 'noShow')}
                      >
                        No Show
                      </Button>
                    </div>
                  :
                  (selectedClient.status === 'noShow' || selectedClient.status === 'finished') ?
                    null
                    :
                    (moment().diff(today + ' ' + selectedTimeSlot+':00') > 0) ?
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <Button
                          variant='contained'
                          color='success'
                          disabled={appointmentBtnDisabled}
                          className="table-btn available-btn"
                          onClick={() => updateAppointment(selectedClient.appointmentID, 'completed')}
                        >
                          Completed
                        </Button>
                      </div>
                      : null
              }

            </div>
          </div>

          {!addServiceArea && <div>
            <center>
              <div className='row mb-4'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <span style={{ fontWeight: '600', fontSize: '18px' }}>Add Service</span>
                </div>
              </div>
            </center>

            <div>
              {addServiceError && <div className='alert alert-danger'>
                Service Name and Price required *
              </div>}

              <label className='form-label' style={{ color: '#707e7e', fontWeight: '600' }}>Service Name *</label>
              <select
                className='form-select'
                aria-label='Disabled select example'
                style={{ fontWeight: '600', border: 'none', borderRadius: 0, borderBottom: '1px solid #1AB084' }}
                onChange={e => setAddedService({
                  ...addedService,
                  serviceID: e.target.value,
                  serviceName: e.target.value
                })}
              >
                <option selected={true} hidden={true}>Please Select</option>
                {
                  serviceCategories.map(category => {
                    return (
                      <option value={category}>{category}</option>
                    );
                  })
                }
              </select>
            </div>

            <div className='mt-3'>
              <label htmlFor='formFileSm' className='form-label' style={{ color: '#707e7e', fontWeight: '600' }}>Service
                Price (LKR)*</label>
              <input
                className='form-control form-control-sm'
                id='formFileSm'
                type='text'
                style={{ fontWeight: '600', border: 'none', borderRadius: 0, borderBottom: '1px solid #1AB084' }}
                onChange={e => setAddedService({ ...addedService, servicePrice: e.target.value })}
              />
            </div>

            <div className='row mt-3'>
              <div className='col-sm-6 col-md-6 col-lg-6'>
                <Button
                  variant='contained'
                  color='success'
                  className='table-btn available-btn'
                  onClick={() => pushAddedService()}
                >
                  Add Service
                </Button>
              </div>
              <div className='col-sm-6 col-md-6 col-lg-6'>
                <Button
                  variant='contained'
                  color='warning'
                  className='table-btn unavailable-btn'
                  onClick={() => {
                    setDetailsAreaHide(false);
                    setAddServiceArea(true);
                  }}
                >
                  Back
                </Button>
              </div>

            </div>
          </div>}

        </Modal.Body>
      </Modal>

      {
        (sts.length > 0 && employees.length > 0 && services.length > 0)?
        <Container maxWidth='xl' style={{maxWidth: '100%'}}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
          <Typography variant='h4' gutterBottom>
            Calendar View
          </Typography>
        </Stack>

          <div className="row">
            <div className='col-sm-6 col-md-6 col-lg-6'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  // label="Basic example"
                  className='float-start'
                  value={today}
                  disabled={disabled}
                  onChange={(value) => {
                    handleSelectedDate(moment(value).format('YYYY-MM-DD'));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6 mt-3'>
              <Button
                id='btn_refresh'
                className='float-end'
                variant='contained'
                color='info'
                disabled={disabled}
                style={{ color: 'white', minWidth: '100px', borderRadius: '2px'}}
                onClick={() => refresh()}
              >
                Refresh
              </Button>
            </div>
          </div>
        <div className='row'>
          <div className='col-sm-3 col-md-3 col-lg-3 mb-3'>
          </div>
          <div className='col-sm-9 col-md-9 col-lg-9 mb-2'>
            <span style={{ fontWeight: '600' }}
                  className='float-end'>Last Updated: {moment(lastUpdate).format('YYYY-MM-DD LTS')}</span>
          </div>
        </div>

        <Card>
          <Scrollbar>
            {isLoading && <div className='row text-center' style={{ minHeight: '500px', position: 'relative' }}>
              <div className='col-sm-12'>
                <Rings
                  height='100'
                  width='100'
                  color='#1AB084'
                  ariaLabel='loading'
                  wrapperStyle={{
                    display: 'block',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    textAlign: 'center',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
              </div>
            </div>}

            {!process_arr_loaded && !isLoading && <TableContainer sx={{ minWidth: 800 }} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
              <Table>
                <UserListHead
                  order={order}
                  headLabel={employees}
                  rowCount={employees.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {
                    sts.map((value, key) => {
                      return (
                        <TableRow
                          hover
                          key={key}
                        >
                          <TableCell padding='checkbox' style={{textAlign: 'center', borderRight: '1px solid #f1f3f4'}}>
                            <div className='row' style={{ minWidth: '100px' }}>
                              <div className='col-sm-12'>
                                <Typography variant='subtitle2' noWrap>
                                  <b>{moment(value, ["HH:mm"]).format("hh:mm A")}</b>
                                </Typography>
                              </div>
                            </div>
                          </TableCell>

                          {
                            !process_arr_loaded && isLoaded && employees.map((employee, key) => {
                              let count = 0;

                              if (process_arr[key].length == 0) {
                                if (isEmpAvailable(employee, value)) {
                                  return (
                                    <TableCell style={{textAlign: 'center', borderRight: '1px solid #f1f3f4'}}>
                                      {
                                        (moment().diff(today + ' ' + value+':00') > 0) ?
                                          <Button
                                            variant='contained'
                                            className="table-btn unavailable-btn"
                                          >
                                            Unavailable
                                          </Button>
                                          :
                                          <Button
                                            variant='contained'
                                            className="table-btn available-btn"
                                            onClick={() => {
                                              setSelectedStylistAndTimeSlot(employee, value);
                                              changeStage(4);
                                              setModalShow(!modalShow);
                                            }}
                                          >
                                            Available
                                          </Button>
                                      }
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell style={{textAlign: 'center', borderRight: '1px solid #f1f3f4'}}>
                                      <Button
                                        variant='contained'
                                        className="table-btn unavailable-btn"
                                      >
                                        Unavailable
                                      </Button>
                                    </TableCell>
                                  );
                                }
                              } else {
                                return (
                                  process_arr[key].map(appointment => {
                                    count += 1;
                                    let time = moment.unix(appointment.timeSlot.seconds).format('HH:mm');

                                    if ((appointment.stylistID === employee.docID)) {
                                      if (time == value) {
                                        return (
                                          <TableCell style={{textAlign: 'center', borderRight: '1px solid #f1f3f4'}}>
                                            <Button
                                              variant='contained'
                                              className={(appointment.status === 'finished') ? 'table-btn completed-btn' : (appointment.status === 'noShow') ? 'table-btn noShow-btn' : 'table-btn appointment-btn'}
                                              onClick={() => {
                                                setSelectedStylistAndTimeSlot(employee, value);
                                                openClientModal(appointment);
                                              }}
                                            >
                                              {appointment.customerID.split(' ')[0]}
                                            </Button>
                                          </TableCell>
                                        );
                                      } else {
                                        if ((count < 2) && !checkHasAppointTime(value, process_arr[key])) {
                                          if (isEmpAvailable(employee, value)) {
                                            return (
                                              <TableCell style={{textAlign: 'center', borderRight: '1px solid #f1f3f4'}}>
                                                {
                                                  (moment().diff(today + ' ' + value+':00') > 0) ?
                                                    <Button
                                                      variant='contained'
                                                      className="table-btn unavailable-btn"
                                                    >
                                                      Unavailable
                                                    </Button>
                                                    :
                                                    <Button
                                                      variant='contained'
                                                      className="table-btn available-btn"
                                                      onClick={() => {
                                                        setSelectedStylistAndTimeSlot(employee, value);
                                                        changeStage(4);
                                                        setModalShow(!modalShow);
                                                      }}
                                                    >
                                                      Available
                                                    </Button>
                                                }
                                              </TableCell>
                                            );
                                          } else {
                                            return (
                                              <TableCell style={{textAlign: 'center', borderRight: '1px solid #f1f3f4'}}>
                                                <Button
                                                  variant='contained'
                                                  className="table-btn unavailable-btn"
                                                >
                                                  Unavailable
                                                </Button>
                                              </TableCell>
                                            );
                                          }

                                        }
                                      }
                                    }


                                  })
                                );
                              }

                            })
                          }
                        </TableRow>
                      );
                    })
                  }

                </TableBody>
              </Table>
            </TableContainer>
            }
          </Scrollbar>
        </Card>
      </Container> :
          <Container maxWidth='xl' className="mt-5">
            <center>
              <div style={{border: '2px solid #E74C3C', borderRadius: '10px', padding: '10px', backgroundColor: '#ff4255'}}>
                <h2 style={{fontWeight: 600, color: 'white'}}>{emptyMsg}</h2>
              </div>
            </center>
          </Container>
      }
    </Page>
  );
}
