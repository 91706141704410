import api from './Api';

export const getAuth = () => {
    return api.firebaseConfig;
}

export const setToken = (token) => {
    localStorage.setItem('refresh-token', token);
}

export const setIsLogged = (status) => {
    localStorage.setItem('is-logged', status);
}

export const setUserDetails = (data) => {
    let userDetails = {
        uid: data.uid,
        email: data.email
    }
    localStorage.setItem('user-details', JSON.stringify(userDetails));
}

export const setMerchantDetails = (data) => {
    let userDetails = {
        name: data.name,
        image: data.image
    }
    localStorage.setItem('merchant-details', JSON.stringify(userDetails));
}

export const getMerchantDetails = () => {
    return JSON.parse(localStorage.getItem('merchant-details'));
}

export const setServiceCategories = (data) => {
    localStorage.setItem('service-categories', JSON.stringify(data));
}

export const getServiceCategories = () => {
    return JSON.parse(localStorage.getItem('service-categories'));
}

export const setUserRoles = (data) => {
    localStorage.setItem('user-roles', JSON.stringify(data));
}

export const getUser = () => {
    return JSON.parse(localStorage.getItem('user-details'));
}

export const getIsLogged = () => {
    return localStorage.getItem('is-logged') || false;
}

export const logout = () => {
    if(getIsLogged()){
        localStorage.clear();
        window.location.reload();
    }
}

//days
export const setDays = (data) => {
    localStorage.setItem('days', JSON.stringify(data));
}

export const getDays = () => {
    return JSON.parse(localStorage.getItem('days'));
}

//time slots
export const setAppointmentTimeSlots = (data) => {
    localStorage.setItem('time-slots', JSON.stringify(data));
}

export const getAppointmentTimeSlots = () => {
    return JSON.parse(localStorage.getItem('time-slots'));
}

export const setTimeSlotGap = (data) => {
    localStorage.setItem('time-slot-gap', JSON.stringify(data));
}

export const getTimeSlotGap = () => {
    return JSON.parse(localStorage.getItem('time-slot-gap'));
}

//stylists
export const setStylists = (data) => {
    localStorage.setItem('stylists', JSON.stringify(data));
}

export const getStylists = () => {
    return JSON.parse(localStorage.getItem('stylists'));
}

//services
export const setServices = (data) => {
    localStorage.setItem('services', JSON.stringify(data));
}

export const getServices = () => {
    return JSON.parse(localStorage.getItem('services'));
}
