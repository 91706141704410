import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar
} from '../components/_dashboard/products';
//
import PRODUCTS from '../_mocks_/products';
// ----------------------------------------------------------------------


////////////firebase imports
import { collection, getDocs } from 'firebase/firebase';
import appServices from '../Helpers/Services'
////////////firebase imports


export default function EcommerceShop() {
  const [openFilter, setOpenFilter] = useState(false);

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  // Get a list of appointments from your database
  const db = appServices.executeFirebaseConfig();

  async function getAppointments(db) {
    const citiesCol = collection(db, 'appointment');
    const citySnapshot = await getDocs(citiesCol);
    const cityList = citySnapshot.docs.map(doc => doc.data());
    return cityList;
  }

  useEffect(() => {
    getAppointments(db).then(r => {
      console.log(r)
    }).catch(e=>console.log(e));
  },[])

  return (
    <Page title="Dashboard: Products | Perks">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Products
        </Typography>

        {/*<Stack*/}
        {/*  direction="row"*/}
        {/*  flexWrap="wrap-reverse"*/}
        {/*  alignItems="center"*/}
        {/*  justifyContent="flex-end"*/}
        {/*  sx={{ mb: 5 }}*/}
        {/*>*/}
        {/*  <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>*/}
        {/*    <ProductFilterSidebar*/}
        {/*      formik={formik}*/}
        {/*      isOpenFilter={openFilter}*/}
        {/*      onResetFilter={handleResetFilter}*/}
        {/*      onOpenFilter={handleOpenFilter}*/}
        {/*      onCloseFilter={handleCloseFilter}*/}
        {/*    />*/}
        {/*    <ProductSort />*/}
        {/*  </Stack>*/}
        {/*</Stack>*/}

        {/*<ProductList products={PRODUCTS} />*/}
        {/*<ProductCartWidget />*/}
      </Container>
    </Page>
  );
}
