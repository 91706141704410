import api from './Api'
import firebase from 'firebase';

class Services {
  executeFirebaseConfig() {
    firebase.initializeApp(api.firebaseConfig);
    return firebase.firestore();;
  }
}

export default new Services();
