import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import calendarOutline from '@iconify/icons-eva/calendar-outline'
import messages from '@iconify/icons-eva/message-circle-outline'
import person from '@iconify/icons-eva/person-fill'
import services from '@iconify/icons-eva/shopping-bag-outline'
import settings from '@iconify/icons-eva/settings-2-fill'

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/home',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Appointments',
    path: '/dashboard/appointments',
    icon: getIcon(calendarOutline)
  },
  {
    title: 'Customers',
    path: '/under-construction',
    // path: '/dashboard/products',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Messages',
    path: '/under-construction',
    // path: '/dashboard/blog',
    icon: getIcon(messages)
  },
  {
    title: 'Employee',
    path: '/under-construction',
    // path: '/login',
    icon: getIcon(person)
  },
  {
    title: 'Services',
    path: '/under-construction',
    // path: '/register',
    icon: getIcon(services)
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(settings)
  // }
];

export default sidebarConfig;
