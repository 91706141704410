import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
// import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
// import AuthSocial from '../components/authentication/AuthSocial';
// import Logo from '../components/Logo';
import background from './logo_background.png'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
    backgroundImage: 'url('+background+')'
  }
}));

const RootStyleCard = styled(Card)(({ theme }) => ({
  // boxShadow: '0px 0px 15px -10px #000000',
  borderRadius: '20px !important',
  border: 'none !important',
  textAlign: 'left',
  padding: theme.spacing(5, 0),
  color: theme.palette.common.darker,
  backgroundColor: theme.palette.background.default,
  marginBottom: '100px'
  // webkitBoxShadow: '0px 0px 15px -2px #000000',
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | Perks">
      {/*<AuthLayout>*/}
      {/*  Don’t have an account? &nbsp;*/}
      {/*  <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">*/}
      {/*    Get started*/}
      {/*  </Link>*/}
      {/*</AuthLayout>*/}

      {/*<MHidden width="mdDown">*/}
      {/*  <SectionStyle>*/}
      {/*    <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>*/}
      {/*      Login in to Perks*/}
      {/*    </Typography>*/}
      {/*    /!*<img src="/static/illustrations/illustration_login.png" alt="login" />*!/*/}
      {/*  </SectionStyle>*/}
      {/*</MHidden>*/}

      <Container maxWidth="sm" style={{width: '360px'}} >
        <ContentStyle>
          <div className="row mt-3">
            <img className="rounded mx-auto d-block mt-lg-5 mb-5" src="/static/perks-white.png" alt='Logo' style={{ width: 180, height: '100%' }} />
            <RootStyleCard>
              <Stack sx={{ mb: 5 }}>
                <Typography variant="h4" gutterBottom className="text-center">
                  {/*<Logo />*/}
                  Login
                </Typography>
                {/*<Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>*/}
              </Stack>
              {/*<AuthSocial />*/}

              <div className="row mt-2">
                <div className="col-sm-12" style={{fontWeight: '600'}}>
                  <LoginForm />
                </div>
              </div>

            </RootStyleCard>
          </div>


          {/*<MHidden width="smUp">*/}
          {/*  <Typography variant="body2" align="center" sx={{ mt: 3 }}>*/}
          {/*    Don’t have an account?&nbsp;*/}
          {/*    <Link variant="subtitle2" component={RouterLink} to="register">*/}
          {/*      Get started*/}
          {/*    </Link>*/}
          {/*  </Typography>*/}
          {/*</MHidden>*/}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
