class Api {
    firebaseConfig = {
        apiKey: "AIzaSyCsidakbyfF8vdyDz19lu7UBiaOs8qGrcI",
        authDomain: "perks-68132.firebaseapp.com",
        databaseURL: "http://perks-68132-default-rtdb.firebaseio.com",
        projectId: "perks-68132",
        storageBucket: "perks-68132.appspot.com",
        messagingSenderId: "1089503658800",
        appId: "1:1089503658800:web:80f01f21f967e693637ef5",
        measurementId: "G-LZ6XJ0P2W7"
    }
}

export default new Api();