import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import '../../../pages/Login.css'

import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';

import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';

import { LoadingButton } from '@mui/lab';
import {
  setToken,
  setIsLogged,
  setUserDetails,
  setAppointmentTimeSlots,
  setStylists,
  setDays,
  setServices,
  setTimeSlotGap,
  setMerchantDetails,
  setServiceCategories
} from '../../../Helpers/Util';
import { Rings } from  'react-loader-spinner';

// ----------------------------------------------------------------------

////////////firebase
import firebase from 'firebase/firebase';
////////////firebase

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////





  ////////////firebase
  const database = firebase.firestore();
  ////////////firebase

  //////custom data
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loader, setLoader] = useState('hide');

  let openingHours  = [];
  let interval  = 0;
  let days = [];
  let times = [];

  //get time hors from firebase
  async function getTimeHoursFireBase(uid) {
    return database.collection('merchant').doc(uid).get();
  }

  //get stylist from firebase
  function getStylistFireBase(uid) {
    return database.collection('merchant').doc(uid).collection('stylists').get();
  }

  //get services from firebase
  function getServicesFireBase(uid) {
    return database.collection('merchant').doc(uid).collection('services').get();
  }

  /////////time slots functions
  function generateTimeSlots(time, minutes) {
    let date = new Date(new Date('01/01/2022 ' + time + ':00').getTime() + minutes * 60000);

    let tempTime = ((date.getHours().toString().length == 1) ? '0' + date.getHours() : date.getHours()) + ':' +
      ((date.getMinutes().toString().length == 1) ? '0' + date.getMinutes() : date.getMinutes());
    //+':'+ ((date.getSeconds().toString().length == 1) ? '0' + date.getSeconds() : date.getSeconds());

    return tempTime;
  }

  function loadTimeSlots(start_time, endtime, interval) {
    let starttime = start_time;

    let time_slots = [];

    time_slots.push(start_time);

    while (starttime != endtime) {
      starttime = generateTimeSlots(starttime, interval);

      if(starttime > endtime)
        break;

      time_slots.push(starttime);

      if(starttime == endtime)
        break;
    }

    return time_slots;
  }

  function runOpeningHours() {
    openingHours.map(value => {
      let ts = loadTimeSlots(value.start, value.end, interval);

      let day = {day: value.day, start_time: value.start, end_time: value.end, open:value.open, timeslots: ts}

      days.push(day);
      times.push(ts);
    })

    setDays(days);
    setAppointmentTimeSlots(times);
  }
  /////////time slots functions

  function submitLogin(e) {
    e.preventDefault(e);

    if(email === '' || password === '') {
      popUpAlert('Required', 'Please Enter Email and Password', 'error')
    } else {
      setLoader('show');

      firebase.auth().signInWithEmailAndPassword(email, password)
        .then((response) => {
          setIsLogged(true);
          setToken(response.user.refreshToken);
          setUserDetails(response.user);

          let stylists = [];
          let services = [];

          // get and set merchant stylists
          getStylistFireBase(response.user.uid)
            .then(data => {
              data.docs.map(value => {
                stylists.push(value.data());
              })

              setStylists(stylists);

            }).catch(e => console.log(e));

          //get and set merchant services
          getServicesFireBase(response.user.uid)
            .then(data => {
              data.docs.map(value => {
                services.push(value.data());
              })

              setServices(services);
            }).catch(e => console.log(e));

          getTimeHoursFireBase(response.user.uid)
            .then(data => {
              openingHours = data.data().openingHours;
              interval = data.data().timeSlotGap;

              setMerchantDetails(data.data());
              setServiceCategories(data.data().serviceCategroy);

              setTimeSlotGap(interval);

              runOpeningHours();

              navigate('/dashboard/home');
              setLoader('hide');
            }).catch(e => console.log(e));
        }).catch((e) => {console.log(e);
        if(e.code === 'auth/user-not-found') {
          popUpAlert('Invalid', e.message, 'error')
          setLoader('hide');
        }

        if(e.code === 'auth/wrong-password') {
          popUpAlert('Invalid', e.message, 'error')
          setLoader('hide');
        }
      });
    }
  }

  //add script of sweetalert 2
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "//cdn.jsdelivr.net/npm/sweetalert2@11";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  //sweet alert popup method
  function popUpAlert(title = '', text = '', icon = 'success') {
    window.Swal.fire({
      icon: icon,
      title: title,
      text: text,
      allowOutsideClick: false
    })
  }

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={submitLogin}
      >
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Email Address"
            variant="standard"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          <TextField
            fullWidth
            label="Password"
            variant="standard"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/*<FormControlLabel*/}
          {/*  control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}*/}
          {/*  label="Remember me"*/}
          {/*/>*/}

          {/*<Link component={RouterLink} variant="subtitle2" to="#">*/}
          {/*  Forgot password?*/}
          {/*</Link>*/}
        </Stack>

        <LoadingButton
          fullWidth
          style={{backgroundColor: '#1AB084'}}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>

      {/*page loader*/}
      <div className={"overly-loader " + loader}>
        <div className="loader-img">
          <center>
            <Rings
              height='100'
              width='100'
              color='#1AB084'
              ariaLabel='loading'
            />
            <br />
            <p align="center">Logging...</p>
          </center>

        </div>
      </div>
      {/*page loader*/}
    </FormikProvider>
  );
}
